import { Component } from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-hood',
  templateUrl: './hood.component.html',
  styles: []
})
export class HoodComponent {

}
