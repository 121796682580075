import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CarouselModule} from 'ngx-carousel-ease';
import {DecorationsModule} from '../decorations/decorations.module';
import {CoupaComponent} from './integrations-page/erps/coupa/coupa.component';
import {DataforceComponent} from './integrations-page/erps/dataforce/dataforce.component';
import {FftComponent} from './integrations-page/erps/fft/fft.component';
import {PlentyMarketsComponent} from './integrations-page/erps/plenty-markets/plenty-markets.component';
import {ProdressComponent} from './integrations-page/erps/prodress/prodress.component';
import {SapComponent} from './integrations-page/erps/sap/sap.component';
import {WeclappComponent} from './integrations-page/erps/weclapp/weclapp.component';
import {XentralComponent} from './integrations-page/erps/xentral/xentral.component';
import {HgkComponent} from './integrations-page/marketplaces/hgk/hgk.component';
import {HoodComponent} from './integrations-page/marketplaces/hood/hood.component';
import {RefurbedComponent} from './integrations-page/marketplaces/refurbed/refurbed.component';
import {UniteComponent} from './integrations-page/marketplaces/unite/unite.component';
import {PlytixComponent} from './integrations-page/pim/plytix/plytix.component';
import {CopecartComponent} from './integrations-page/shops/copecart/copecart.component';
import {DehnerComponent} from './integrations-page/shops/dehner/dehner.component';
import {DigistoreComponent} from './integrations-page/shops/digistore/digistore.component';
import {ShopifyComponent} from './integrations-page/shops/shopify/shopify.component';
import {LandingPageComponent} from "./landing-page/landing-page.component";
import {SharedModule} from "../shared/shared.module";
import {PrivacyPageComponent} from "./privacy-page/privacy-page.component";
import {ImprintPageComponent} from "./imprint-page/imprint-page.component";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {PricingPageComponent} from './pricing-page/pricing-page.component';
import {TranslateModule} from "@ngx-translate/core";
import {AboutUsComponent} from './about-us/about-us.component';
import {IntegrationsPageComponent} from './integrations-page/integrations-page.component';
import {XchangehubPageComponent} from './xchangehub-page/xchangehub-page.component';

@NgModule({
    declarations: [
        LandingPageComponent,
        PrivacyPageComponent,
        ImprintPageComponent,
        PricingPageComponent,
        AboutUsComponent,
        IntegrationsPageComponent,
        XchangehubPageComponent,
        WeclappComponent,
        SapComponent,
        PlentyMarketsComponent,
        FftComponent,
        XentralComponent,
        ProdressComponent,
        CoupaComponent,
        DataforceComponent,
        HgkComponent,
        RefurbedComponent,
        HoodComponent,
        UniteComponent,
        CopecartComponent,
        DehnerComponent,
        DigistoreComponent,
        ShopifyComponent,
        PlytixComponent
    ],
    providers: [],
    imports: [
        SharedModule,
        CommonModule,
        RouterModule,
        TranslateModule,
        DecorationsModule,
        CarouselModule,
        FormsModule,
    ]
})
export class PagesModule {
}
