import { Component } from '@angular/core';

@Component({
  selector: 'app-shopify',
  templateUrl: './shopify.component.html',
  styles: []
})
export class ShopifyComponent {

}
