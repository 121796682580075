import { Component } from '@angular/core';

@Component({
  selector: 'app-digistore',
  templateUrl: './digistore.component.html',
  styles: []
})
export class DigistoreComponent {

}
