import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import AOS from 'aos';
import {SEOService} from '../../service/seo-service/seo.service';

@Component({
  selector: 'app-integrations-page',
  templateUrl: './integrations-page.component.html',
  styleUrls: ['./integrations-page.component.scss']
})
export class IntegrationsPageComponent implements OnInit {

  lang: string = 'en';

  oms = "XENTRAL"
  marketplace = "REFURBED"
  shop = "SHOPIFY"
  pim = "PLYTIX"

  constructor(
      protected seoService: SEOService,
      protected translate: TranslateService
  ) {
  }

  ngOnInit(): void {

    AOS.init();

    this.seoService.setPageTitle(this.translate.instant('title'));
    this.seoService.updateKeyWords(['SevData', 'Data Interchange', 'DIaaS', 'Xentral', 'eCommerce', 'Fulfillment', 'Integration', 'Data Exchange'])

    this.translate.onLangChange.subscribe((lang) => {
      this.lang = lang.lang;
    });
  }
}
