import { Component } from '@angular/core';

@Component({
  selector: 'app-coupa',
  templateUrl: './coupa.component.html',
  styles: [
  ]
})
export class CoupaComponent {

}
