<table class="w-full text-left">
    <colgroup>
        <col class="w-4/12">
        <col class="w-4/12">
        <col class="w-4/12">
    </colgroup>
    <thead>
    <tr class="-mx-4 rounded-lg bg-gray-50 px-4 py-3 text-sm/6 font-semibold text-gray-950">
        <td class="p-0 w-4/12">
        </td>
        <th scope="col" class="p-0 w-4/12">
            <div class="text-sm/5 font-semibold text-center">
                Export
            </div>
        </th>
        <th scope="col" class="p-0 w-4/12">
            <div class="text-sm/5 font-semibold text-center">
                Import
            </div>
        </th>
    </tr>
    </thead>
    <tbody class="group">
    <tr class="border-b border-gray-100 last:border-none">
        <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.orders"></th>
        <td class="p-2 text-center text-xs italic"></td>
        <td class="p-2 text-center text-xs italic">
            <svg class="h-6 w-5 flex-none text-teal-600 inline-block mx-auto" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z" clip-rule="evenodd" />
            </svg>
        </td>
    </tr>
    <tr class="border-b border-gray-100 last:border-none">
        <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.ordersConfirmation"></th>
        <td class="p-2 text-center text-xs italic">
            <svg class="h-6 w-5 flex-none text-teal-600 inline-block mx-auto" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z" clip-rule="evenodd" />
            </svg>
        </td>
        <td class="p-2 text-center text-xs italic"></td>
    </tr>
    <tr class="border-b border-gray-100 last:border-none">
        <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.deliveryNote"></th>
        <td class="p-2 text-center text-xs italic"></td>
        <td class="p-2 text-center text-xs italic">
            <svg class="h-6 w-5 flex-none text-teal-600 inline-block mx-auto" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z" clip-rule="evenodd" />
            </svg>
        </td>
    </tr>
    <tr class="border-b border-gray-100 last:border-none">
        <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.deliveryNoteConfirmation"></th>
        <td class="p-2 text-center text-xs italic">
            <svg class="h-6 w-5 flex-none text-teal-600 inline-block mx-auto" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z" clip-rule="evenodd" />
            </svg>
        </td>
        <td class="p-2 text-center text-xs italic"></td>
    </tr>
    <tr class="border-b border-gray-100 last:border-none">
        <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.purchaseOrder"></th>
        <td class="p-2 text-center text-xs italic"></td>
        <td class="p-2 text-center text-xs italic">
            <svg class="h-6 w-5 flex-none text-teal-600 inline-block mx-auto" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z" clip-rule="evenodd" />
            </svg>
        </td>
    </tr>
    <tr class="border-b border-gray-100 last:border-none">
        <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.receipt"></th>
        <td class="p-2 text-center text-xs italic"></td>
        <td class="p-2 text-center text-xs italic"></td>
    </tr>
    <tr class="border-b border-gray-100 last:border-none">
        <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.returns"></th>
        <td class="p-2 text-center text-xs italic"></td>
        <td class="p-2 text-center text-xs italic"></td>
    </tr>
    <tr class="border-b border-gray-100 last:border-none">
        <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.returnsReceipt"></th>
        <td class="p-2 text-center text-xs italic"></td>
        <td class="p-2 text-center text-xs italic"></td>
    </tr>
    <tr class="border-b border-gray-100 last:border-none">
        <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.invoice"></th>
        <td class="p-2 text-center text-xs italic"></td>
        <td class="p-2 text-center text-xs italic"></td>
    </tr>
    <tr class="border-b border-gray-100 last:border-none">
        <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.invoiceReceipt"></th>
        <td class="p-2 text-center text-xs italic"></td>
        <td class="p-2 text-center text-xs italic"></td>
    </tr>
    <tr class="border-b border-gray-100 last:border-none">
        <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.stocks"></th>
        <td class="p-2 text-center text-xs italic">
            <svg class="h-6 w-5 flex-none text-teal-600 inline-block mx-auto" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z" clip-rule="evenodd" />
            </svg>
        </td>
        <td class="p-2 text-center text-xs italic"></td>
    </tr>
    <tr class="border-b border-gray-100 last:border-none">
        <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.article"></th>
        <td class="p-2 text-center text-xs italic"></td>
        <td class="p-2 text-center text-xs italic">
            <svg class="h-6 w-5 flex-none text-teal-600 inline-block mx-auto" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z" clip-rule="evenodd" />
            </svg>
        </td>
    </tr>
    <tr class="border-b border-gray-100 last:border-none">
        <th scope="row" class="px-0 py-2 text-sm/6 text-gray-600 font-semibold" translate="integrations.types.address"></th>
        <td class="p-2 text-center text-xs italic"></td>
        <td class="p-2 text-center text-xs italic"></td>
    </tr>

    </tbody>
</table>