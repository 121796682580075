import { Component } from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-hgk',
  templateUrl: './hgk.component.html',
  styles: []
})
export class HgkComponent {

}
