import { Component } from '@angular/core';

@Component({
  selector: 'app-plytix',
  templateUrl: './plytix.component.html',
  styles: []
})
export class PlytixComponent {

}
