import { Component } from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-refurbed',
  templateUrl: './refurbed.component.html',
  styles: []
})
export class RefurbedComponent {

}
