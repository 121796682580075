import { Component } from '@angular/core';

@Component({
  selector: 'app-xentral',
  templateUrl: './xentral.component.html',
  styles: []
})
export class XentralComponent {

}
