import { Component } from '@angular/core';

@Component({
  selector: 'app-copecart',
  templateUrl: './copecart.component.html',
  styles: []
})
export class CopecartComponent {

}
