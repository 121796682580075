import {Component} from '@angular/core';

@Component({
    selector: 'app-weclapp',
    templateUrl: './weclapp.component.html',
    styles: []
})
export class WeclappComponent {

}
