<div class="bg-slate-700 py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:mx-0">
            <h2 class="text-4xl font-bold tracking-tight text-white sm:text-6xl" translate="integrations.headline"></h2>
            <p class="mt-6 text-lg leading-8 text-gray-300" translate="integrations.text"></p>
        </div>
    </div>
</div>

<div class="bg-white px-6 py-16 lg:px-8" #target>
    <div class="mx-auto max-w-7xl text-base leading-7 text-gray-700">
        
        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" translate="integrations.oms.headline"></h1>
        <p class="mt-6 text-xl leading-8" translate="integrations.oms.text"></p>

        <div class="mt-10">
            <div class="grid grid-cols-1 sm:hidden">
                <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
                <select [(ngModel)]="oms" aria-label="Select a tab" class="col-start-1 bg-gray-50 row-start-1 w-full rounded-md py-2 pr-8 pl-3 text-base text-gray-900 outline-1 -outline-offset-1 outline-gray-300 focus:outline-2 focus:-outline-offset-2 focus:outline-teal-600">
                    <option value="XENTRAL">Xentral</option>
                    <option value="WECLAPP">weclapp</option>
                    <option value="SAP">SAP - Business By Design</option>
                    <option value="PLENTY_MARKETS">Plenty Markets</option>
                    <option value="FFT">Fulfillment Tools</option>
                    <option value="PRODRESS">Prodress</option>
                    <option value="COUPA">Coupa</option>
                    <option value="DATAFORCE">Dataforce</option>
                </select>
            </div>
            <div class="hidden sm:block">
                <nav class="isolate flex divide-x divide-gray-200 rounded-lg shadow-sm" aria-label="Tabs">
                    <!-- Current: "text-gray-900", Default: "text-gray-500 hover:text-gray-700" -->
                    <a (click)="oms = 'XENTRAL'" [class.text-gray-900]="oms == 'XENTRAL'" class="group cursor-pointer relative justify-center bg-gray-50 items-center flex min-w-0 flex-1 overflow-hidden rounded-l-lg px-4 py-4 text-center text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700 focus:z-10">
                        <span>Xentral</span>
                        <span aria-hidden="true" class="absolute inset-x-0 bottom-0 h-0.5 bg-transparent" [class.bg-teal-500]="oms == 'XENTRAL'"></span>
                    </a>
                    <a (click)="oms = 'WECLAPP'" [class.text-gray-900]="oms == 'WECLAPP'" class="group cursor-pointer relative justify-center bg-gray-50 items-center flex min-w-0 flex-1 overflow-hidden px-4 py-4 text-center text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700 focus:z-10">
                        <span>weclapp</span>
                        <span aria-hidden="true" class="absolute inset-x-0 bottom-0 h-0.5 bg-transparent" [class.bg-teal-500]="oms == 'WECLAPP'"></span>
                    </a>
                    <a (click)="oms = 'SAP'" [class.text-gray-900]="oms == 'SAP'" class="group cursor-pointer relative justify-center bg-gray-50 items-center flex min-w-0 flex-1 overflow-hidden px-4 py-4 text-center text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700 focus:z-10">
                        <span>SAP <br> Business By Design</span>
                        <span aria-hidden="true" class="absolute inset-x-0 bottom-0 h-0.5 bg-transparent" [class.bg-teal-500]="oms == 'SAP'"></span>
                    </a>
                    <a (click)="oms = 'PLENTY_MARKETS'" [class.text-gray-900]="oms == 'PLENTY_MARKETS'" class="group cursor-pointer relative justify-center bg-gray-50 items-center flex min-w-0 flex-1 overflow-hidden px-4 py-4 text-center text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10" aria-current="page">
                        <span>Plenty Markets</span>
                        <span aria-hidden="true" class="absolute inset-x-0 bottom-0 h-0.5 bg-transparent" [class.bg-teal-500]="oms == 'PLENTY_MARKETS'"></span>
                    </a>
                    <a (click)="oms = 'FFT'" [class.text-gray-900]="oms == 'FFT'" class="group cursor-pointer relative justify-center bg-gray-50 items-center flex min-w-0 flex-1 overflow-hidden px-4 py-4 text-center text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700 focus:z-10">
                        <span>Fulfillment Tools</span>
                        <span aria-hidden="true" class="absolute inset-x-0 bottom-0 h-0.5 bg-transparent" [class.bg-teal-500]="oms == 'FFT'"></span>
                    </a>
                    <a (click)="oms = 'PRODRESS'" [class.text-gray-900]="oms == 'PRODRESS'" class="group cursor-pointer relative justify-center bg-gray-50 items-center flex min-w-0 flex-1 overflow-hidden px-4 py-4 text-center text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700 focus:z-10">
                        <span>Prodress</span>
                        <span aria-hidden="true" class="absolute inset-x-0 bottom-0 h-0.5 bg-transparent" [class.bg-teal-500]="oms == 'PRODRESS'"></span>
                    </a>
                    <a (click)="oms = 'COUPA'" [class.text-gray-900]="oms == 'COUPA'" class="group cursor-pointer relative justify-center bg-gray-50 items-center flex min-w-0 flex-1 overflow-hidden px-4 py-4 text-center text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700 focus:z-10">
                        <span>Coupa</span>
                        <span aria-hidden="true" class="absolute inset-x-0 bottom-0 h-0.5 bg-transparent" [class.bg-teal-500]="oms == 'COUPA'"></span>
                    </a>
                    <a (click)="oms = 'DATAFORCE'" [class.text-gray-900]="oms == 'DATAFORCE'" class="group cursor-pointer relative justify-center bg-gray-50 items-center flex min-w-0 flex-1 overflow-hidden rounded-r-lg bg-white px-4 py-4 text-center text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700 focus:z-10">
                        <span>Dataforce</span>
                        <span aria-hidden="true" class="absolute inset-x-0 bottom-0 h-0.5 bg-transparent" [class.bg-teal-500]="oms == 'DATAFORCE'"></span>
                    </a>
                </nav>
            </div>
        </div>

        <div class="mt-10 max-w-7xl">
            <app-weclapp *ngIf="oms == 'WECLAPP'"></app-weclapp>
            <app-sap *ngIf="oms == 'SAP'"></app-sap>
            <app-plenty-markets *ngIf="oms == 'PLENTY_MARKETS'"></app-plenty-markets>
            <app-fft *ngIf="oms == 'FFT'"></app-fft>
            <app-xentral *ngIf="oms == 'XENTRAL'"></app-xentral>
            <app-prodress *ngIf="oms == 'PRODRESS'"></app-prodress>
            <app-coupa *ngIf="oms == 'COUPA'"></app-coupa>
            <app-dataforce *ngIf="oms == 'DATAFORCE'"></app-dataforce>
        </div>
    </div>
</div>

<div class="bg-white px-6 py-16 lg:px-8">
    <div class="mx-auto max-w-7xl text-base leading-7 text-gray-700">
        
        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" translate="integrations.marketplace.headline"></h1>
        <p class="mt-6 text-xl leading-8" translate="integrations.marketplace.text"></p>

        <div class="mt-10">
            <div class="grid grid-cols-1 sm:hidden">
                <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
                <select [(ngModel)]="marketplace" aria-label="Select a tab" class="col-start-1 bg-gray-50 row-start-1 w-full rounded-md py-2 pr-8 pl-3 text-base text-gray-900 outline-1 -outline-offset-1 outline-gray-300 focus:outline-2 focus:-outline-offset-2 focus:outline-teal-600">
                    <option value="REFURBED">Refurbed</option>
                    <option value="HGK">HGK</option>
                    <option value="HOOD">Hood.de</option>
                    <option value="UNITE">Unite</option>
                </select>
            </div>
            <div class="hidden sm:block">
                <nav class="isolate flex divide-x divide-gray-200 rounded-lg shadow-sm" aria-label="Tabs">
                    <!-- Current: "text-gray-900", Default: "text-gray-500 hover:text-gray-700" -->
                    <a (click)="marketplace = 'REFURBED'" [class.text-gray-900]="marketplace == 'REFURBED'" class="group cursor-pointer relative justify-center bg-gray-50 items-center flex min-w-0 flex-1 overflow-hidden rounded-l-lg px-4 py-4 text-center text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700 focus:z-10">
                        <span>Refurbed</span>
                        <span aria-hidden="true" class="absolute inset-x-0 bottom-0 h-0.5 bg-transparent" [class.bg-teal-500]="marketplace == 'REFURBED'"></span>
                    </a>
                    <a (click)="marketplace = 'HGK'" [class.text-gray-900]="marketplace == 'HGK'" class="group cursor-pointer relative justify-center bg-gray-50 items-center flex min-w-0 flex-1 overflow-hidden px-4 py-4 text-center text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700 focus:z-10">
                        <span>HGK</span>
                        <span aria-hidden="true" class="absolute inset-x-0 bottom-0 h-0.5 bg-transparent" [class.bg-teal-500]="marketplace == 'HGK'"></span>
                    </a>
                    <a (click)="marketplace = 'HOOD'" [class.text-gray-900]="marketplace == 'HOOD'" class="group cursor-pointer relative justify-center bg-gray-50 items-center flex min-w-0 flex-1 overflow-hidden px-4 py-4 text-center text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700 focus:z-10">
                        <span>Hood.de</span>
                        <span aria-hidden="true" class="absolute inset-x-0 bottom-0 h-0.5 bg-transparent" [class.bg-teal-500]="marketplace == 'HOOD'"></span>
                    </a>
                    <a (click)="marketplace = 'UNITE'" [class.text-gray-900]="marketplace == 'UNITE'" class="group cursor-pointer relative justify-center bg-gray-50 items-center flex min-w-0 flex-1 overflow-hidden rounded-r-lg bg-white px-4 py-4 text-center text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700 focus:z-10">
                        <span>Unite</span>
                        <span aria-hidden="true" class="absolute inset-x-0 bottom-0 h-0.5 bg-transparent" [class.bg-teal-500]="marketplace == 'UNITE'"></span>
                    </a>
                </nav>
            </div>

            <div class="mt-10 max-w-7xl">
                <app-refurbed *ngIf="marketplace == 'REFURBED'"></app-refurbed>
                <app-hgk *ngIf="marketplace == 'HGK'"></app-hgk>
                <app-hood *ngIf="marketplace == 'HOOD'"></app-hood>
                <app-unite *ngIf="marketplace == 'UNITE'"></app-unite>
            </div>
        </div>
    </div>
</div>

<div class="bg-white px-6 py-16 lg:px-8">
    <div class="mx-auto max-w-7xl text-base leading-7 text-gray-700">
        
        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" translate="integrations.shops.headline"></h1>
        <p class="mt-6 text-xl leading-8" translate="integrations.shops.text"></p>

        <div class="mt-10">
            <div class="grid grid-cols-1 sm:hidden">
                <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
                <select [(ngModel)]="shop" aria-label="Select a tab" class="col-start-1 bg-gray-50 row-start-1 w-full rounded-md py-2 pr-8 pl-3 text-base text-gray-900 outline-1 -outline-offset-1 outline-gray-300 focus:outline-2 focus:-outline-offset-2 focus:outline-teal-600">
                    <option value="SHOPIFY">Shopify</option>
                    <option value="DG24">Digistore24</option>
                    <option value="COPECART">CopeCart</option>
                    <option value="DEHNER">Dehner</option>
                </select>
            </div>
            <div class="hidden sm:block">
                <nav class="isolate flex divide-x divide-gray-200 rounded-lg shadow-sm" aria-label="Tabs">
                    <!-- Current: "text-gray-900", Default: "text-gray-500 hover:text-gray-700" -->
                    <a (click)="shop = 'SHOPIFY'" [class.text-gray-900]="shop == 'SHOPIFY'" class="group cursor-pointer relative justify-center bg-gray-50 items-center flex min-w-0 flex-1 overflow-hidden rounded-l-lg px-4 py-4 text-center text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700 focus:z-10">
                        <span>Shopify</span>
                        <span aria-hidden="true" class="absolute inset-x-0 bottom-0 h-0.5 bg-transparent" [class.bg-teal-500]="shop == 'SHOPIFY'"></span>
                    </a>
                    <a (click)="shop = 'DG24'" [class.text-gray-900]="shop == 'DG24'" class="group cursor-pointer relative justify-center bg-gray-50 items-center flex min-w-0 flex-1 overflow-hidden px-4 py-4 text-center text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700 focus:z-10">
                        <span>Digistore24</span>
                        <span aria-hidden="true" class="absolute inset-x-0 bottom-0 h-0.5 bg-transparent" [class.bg-teal-500]="shop == 'DG24'"></span>
                    </a>
                    <a (click)="shop = 'COPECART'" [class.text-gray-900]="shop == 'COPECART'" class="group cursor-pointer relative justify-center bg-gray-50 items-center flex min-w-0 flex-1 overflow-hidden px-4 py-4 text-center text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700 focus:z-10">
                        <span>CopeCart</span>
                        <span aria-hidden="true" class="absolute inset-x-0 bottom-0 h-0.5 bg-transparent" [class.bg-teal-500]="shop == 'COPECART'"></span>
                    </a>
                    <a (click)="shop = 'DEHNER'" [class.text-gray-900]="shop == 'DEHNER'" class="group cursor-pointer relative justify-center bg-gray-50 items-center flex min-w-0 flex-1 overflow-hidden rounded-r-lg bg-white px-4 py-4 text-center text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700 focus:z-10">
                        <span>Dehner</span>
                        <span aria-hidden="true" class="absolute inset-x-0 bottom-0 h-0.5 bg-transparent" [class.bg-teal-500]="shop == 'DEHNER'"></span>
                    </a>
                </nav>
            </div>

            <div class="mt-10 max-w-7xl">
                <app-shopify *ngIf="shop == 'SHOPIFY'"></app-shopify>
                <app-digistore *ngIf="shop == 'DG24'"></app-digistore>
                <app-copecart *ngIf="shop == 'COPECART'"></app-copecart>
                <app-dehner *ngIf="shop == 'DEHNER'"></app-dehner>
            </div>
        </div>
    </div>
</div>

<div class="bg-white px-6 py-16 lg:px-8">
    <div class="mx-auto max-w-7xl text-base leading-7 text-gray-700">
        
        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" translate="integrations.pim.headline"></h1>
        <p class="mt-6 text-xl leading-8" translate="integrations.pim.text"></p>

        <div class="mt-10">
            <div class="grid grid-cols-1 sm:hidden">
                <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
                <select [(ngModel)]="pim" aria-label="Select a tab" class="col-start-1 bg-gray-50 row-start-1 w-full rounded-md py-2 pr-8 pl-3 text-base text-gray-900 outline-1 -outline-offset-1 outline-gray-300 focus:outline-2 focus:-outline-offset-2 focus:outline-teal-600">
                    <option value="PLYTIX">Plytix</option>
                </select>
            </div>
            <div class="hidden sm:block">
                <nav class="isolate flex divide-x divide-gray-200 rounded-lg shadow-sm" aria-label="Tabs">
                    <!-- Current: "text-gray-900", Default: "text-gray-500 hover:text-gray-700" -->
                    <a (click)="pim = 'PLYTIX'" [class.text-gray-900]="pim == 'PLYTIX'" class="group cursor-pointer relative justify-center bg-gray-50 items-center flex min-w-0 flex-1 overflow-hidden rounded-l-lg rounded-r-lg px-4 py-4 text-center text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700 focus:z-10">
                        <span>Plytix</span>
                        <span aria-hidden="true" class="absolute inset-x-0 bottom-0 h-0.5 bg-transparent" [class.bg-teal-500]="pim == 'PLYTIX'"></span>
                    </a>

                </nav>
            </div>

            <div class="mt-10 max-w-7xl">
                <app-plytix *ngIf="pim == 'PLYTIX'"></app-plytix>
            </div>
        </div>
    </div>
</div>


<div class="bg-white px-6 py-16 lg:px-8">
    <div class="mx-auto max-w-7xl text-base leading-7 text-gray-700">

        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" translate="integrations.support.headline"></h1>
        <p class="mt-6 text-xl leading-8" translate="integrations.support.text"></p>

        <div class="mt-10 mx-auto flex">
            <div class="w-6/12">
                <h3 class="text-base font-semibold text-gray-900" translate="integrations.support.connectionTypes"></h3>

                <ul role="list" class="list-disc pl-10">
                    <li>HTTPS</li>
                    <li>FTP</li>
                    <li>SFTP</li>
                    <li>E-Mail</li>
                    <li>REST</li>
                    <li>GraphQL</li>
                    <li>Webhooks</li>
                    <li>SOAP</li>
                </ul>
            </div>
            <div class="w-6/12">
                <h3 class="text-base font-semibold text-gray-900" translate="integrations.support.fileFormats"></h3>

                <ul role="list" class="list-disc pl-10">
                    <li>JSON</li>
                    <li>XML</li>
                    <li>CSV</li>
                    <li>OpenTrans</li>
                    <li>EDIFACT</li>
                    <li>PHBEST</li>
                    <li>cXML</li>
                    <li>BMEcat</li>
                </ul>
            </div>
        </div>
    </div>
</div>


<div class="overflow-hidden bg-white px-6 py-32 lg:px-8">
    <div class="mx-auto max-w-7xl">
        <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start">
            <div class="lg:pr-4 lg:pt-4">
                <div class="lg:max-w-lg">
                    
                    <p class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl" translate="integrations.flex.headline"></p>
                    <p class="mt-6 leading-8 text-gray-600" translate="integrations.flex.text"></p>
                    <div class="mt-8">
                        <a href="https://sevdata.atlassian.net/servicedesk/customer/portal/3/group/11/create/42" target="_blank" class="inline-flex rounded-md bg-teal-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600" translate="integrations.flex.button"></a>
                    </div>
                </div>
            </div>
            <img src="./assets/images/app-mockup.png" alt="Product screenshot" class="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:ml-0" width="2432" height="1442">
        </div>
    </div>
</div>