import { Component } from '@angular/core';

@Component({
  selector: 'app-dataforce',
  templateUrl: './dataforce.component.html',
  styles: []
})
export class DataforceComponent {

}
