import { Component } from '@angular/core';

@Component({
  selector: 'app-dehner',
  templateUrl: './dehner.component.html',
  styles: []
})
export class DehnerComponent {

}
