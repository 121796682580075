import { Component } from '@angular/core';

@Component({
  selector: 'app-fft',
  templateUrl: './fft.component.html',
  styles: []
})
export class FftComponent {

}
