import { Component } from '@angular/core';

@Component({
  selector: 'app-sap',
  templateUrl: './sap.component.html',
  styles: []
})
export class SapComponent {

}
